<template>
  <div id="story">
    <div class="banner">
      <img src="@/assets/img/story/banner.png" alt="" />
    </div>
    <div class="teacherArea">
      <img src="@/assets/img/story/tx.jpg" alt="" class="teacherAvatar" />
      <div class="teacherName">Edward.Shi</div>
      <div class="teacherDes1">CSON 创始人 &amp; 课程讲师</div>
      <div class="teacherDes2">
        留学两年多，刷题过三千——创业维艰
        <br />
        每个人都有属于自己的留学生活
      </div>
    </div>
    <div class="legal">
      <blockquote>
        <p>
          首先说一下，这篇文章的初衷，是想记录自己的留学生涯，当初想无论进了哪个厂，都会写下这篇文章，只是最后机缘巧合走了创业这条路。但无论我走的哪条路，我都希望以自己的经历，可以给后来人一个借鉴，也是对自己两年多留学生涯的一个总结。其中写了很多自己对于刷题这件事的看法，方法，自己创业的一路过程，和自己的一些人生感悟吧。同时，想尽量去给大家传播一些正能量的东西。
        </p>
      </blockquote>
      <div :class="['jumpNav', left_fixed ? 'is_fixed' : '']">
        <div
          v-for="(it, i) in nav"
          :key="i"
          :class="['st-link', it.is ? 'active' : '']"
          @click="navTo('#toc_' + (i - 0 + 1), i)"
        >
          {{ it.name }}
        </div>
        <div class="st-link" id="totop" @click="toTop">返回顶部</div>
      </div>
      <h2 ref="nav0" id="toc_1"><span>第一年，实习</span></h2>
      <br />
      <p>
        我是16年Spring来美国的，本科是在国内学的软件工程，可以说是标准的科班出身，来美国也是读的CS。刚来就确定肯定不搞科研，因为本科也搞过，但接触后发现自己实在不是搞这东西的料。所以来了目标就非常明确——找工作。那么北美CS找实习找工作考啥？众所周知，没有一个人能逃得过的：
      </p>

      <p><strong>刷题！</strong></p>

      <p>
        有时候回头想想我来美国这两年多，感觉说是留学两年，其实叫刷题的两年更为妥当一些。
      </p>

      <p>
        当时刚来什么都不懂啊，就是各种打听，在网上找资料，刷题啊，Leetcode啊，公司啊，实习啊。那时候我才知道
        Google Facebook
        这种公司真的离我们近在咫尺，只要题刷好了，努努力，这种世界一线公司并不是遥不可及。相比于国内Google
        相传的10轮面试，找个实习只需要两轮电面。只有极个别的几个公司，否则大多不看GPA，看GPA的Yahoo，Oracle这些公司，现状什么大家也都清楚。题能不能做出来才是王道。然后如果运气好就是一套流程
        internship —&gt; work hard —&gt; return offer。
      </p>

      <p>
        那时候也会在盘算，啥时候能把留学的钱挣出来。既然考刷题，得做Leetcode，那就做吧。
      </p>

      <p>于是来美国第一个月，我就开始刷题了。</p>

      <p>
        一谈到刷题，其实大多数人都是非常痛苦的。的确，一开始真的很难受，即使我在别人看来是科班出身。但数据结构都是大二学的，全部都已经还给老师，一开始看个最基础的链表题都不能理解答案写的什么意思，很多算法根本就是有个概念，具体怎么运行已经完全不记得了。就连树的三种遍历，都需要捡起来重新看。所以回过头看看，和非科班比，仅仅是对于算法数据结构这两门，非科班学一个月，就能赶上大多数的科班出身。因为像我这样的，本科没有搞过竞赛ACM，两年时间不用，已经不记得什么了。我想这也是大多数科班毕业的状态。
      </p>

      <p>
        我也是这样啊，所以也只能是硬着头皮硬刷硬做啊。按Leetcode右侧给的类别刷，第一个刷的是链表，树。结果发现代码都看不懂，初来乍到也没有人问，就每天找我本科的兄弟。人家是大神啊，最后去清华保研，后来我听别人说在清华也是大腿级别的存在。当时一月中旬来，下半旬就开始刷起来。美国这晚上8，9点，正好国内放假，就开始逮着我同学问，一点点补，链表什么的怎么运作的，先不管别的先把代码看懂再说。真心感谢我那个兄弟，我经常对他说本科就抱你大腿，研究生了还得抱你大腿。有时候晚上8，9点开始，经常他中午起来就凌晨开始，大年初三还在QQ视频给我讲题，给我讲的时候有时候他妈妈进来我真不好意思，人家是纯粹帮我，回家了谁不想多呆呆放松一下，陪陪父母。所以感激一辈子，我说我以后回国，以后去几次北京，请他吃几次饭。今年回国，这也是我唯一一个找的大学同学，因为赶飞机就在机场请吃的饭。
      </p>

      <p>那一个月，刷了100多题，总算把基础数据结构算法补回来了。</p>

      <p>
        所以一开始补这些基础的时候，如果只有自己一个人搞，太难了，有一个能问，真的能快太多了。后来就自己一点点来，刚开始一天就能刷2-3道，非常痛苦，我做题是按类别做的，每一题求一题多解，每道题都在网上找不同的答案，discuss每天看top
        discussion然后找不同的方法。因为自己基础太差了，所以想每个解法，甚至是写法，都要会。经常有的题能看到7，8种不同的写法，那么这7，8种都理解来一遍，生怕自己落下哪个知识点，但现在来看有用的就那2-3个而已。太多题连看答案都看不懂了，当时还想如果有人能每道题讲一下，这速度不就是飞起了吗。结果发现并没有这样的资源，那时候还认识几个上班了的小姐姐我还和她们说，如果以后有时间我感觉这些题录出来解法感觉会节省很多人时间。只是当时自己太渣，有心无力。
      </p>

      <p>
        一学期下来，刷题近200，当时 Leetcode
        一共也不到400，已经刷了一半了。但大多数是第一个月的100多，后面很多是反复几遍刷。其实第一学期并不能太集中注意力去刷题，一到期中期末，就开始准备考试而放下刷题，安排不好时间，不知道怎么去分配这个时间比。而且弯路走太多了，第一遍，很多解法其实并不是最优而且回过头看其实是不必要的，可能一题有三种写法但掌握一种通用解法就可以了，但大量的时间花在比较和寻找最优解上。
      </p>

      <p>
        这基本就是我Leetcode刷题的第一阶段，总结来说，就是：“分类-多解-基础-遍数”，不求多，但求精。
      </p>

      <p>
        五月考完期末，我同学来我这玩了半个月，这也是我在美国留学生涯唯一一次出去旅游玩，以后就再也没有过了。到了七月开始紧张起来了。因为我知道大多数人都卡在了简历关，简历拒的不要太少，同学NYU的找实习投了500+的公司，最后跟我说还有20天学校申请CPT截止才拿到offer。我CMU的同学FB，Google简历拒的更多。我个人认为，有一部分原因，是因为学校的学生的简历，很多都长一个样，大伙上一个课做同一project最后写的也一样，所以区分度真的不大，我同学也跟我说过这点。所以当时就想，怎么在简历方面脱颖而出，我的做法是专攻+精做。首先project必须有区分度，不能四个project四个方向，我自己选了两个方向，专攻web
        development和iOS，最后我简历是两个web的project
        和两个iOS的project。然后就是找项目做，没项目怎么办，自己找。当时在Udemy的网站做了几个，但现在来看，Udemy的东西大多太基础了，没有几个是完整的一个大的项目，入门学技术可以，但做项目，难。
      </p>

      <p>
        其实CS这门专业，就是自学成才，只有你自己动手做亲自敲代码，才能会。简历也最好是成果，做网站把网站挂上去，做app能在store搜到能下载，做数据分析要有数据量有提升了多少的百分比。我当时做的网站，就自己买了域名最后真正能登陆注册使用，做iOS，上架能下载，其他都是虚的。项目不能水，因为HR都是人精，你项目水不水一看就知道，骗的了别人，骗不了自己。今天水了总有一天会还债。
      </p>

      <p>学CS，绝对的实力才是王道。</p>

      <p>
        等我做完这四个project，已经九月末了，project全是自己一个人做的，那时候真的感觉，我的简历，如果大家都没有实习，我能秒掉90%的人，本科的所有经历荣誉，一律不写，新的开始我要从头做起。web两个project一个注重功能，实现了e-commerce网站，从登陆注册到支付，用php,
        mysql, html, css, javascript
        这些基本技术，但功能全面强大。另一个注重技术，node.js, monogoDB,
        express框架，Angluarjs这些技术要懂。iOS真的就只有swift一个语言了，database是用firebase这个网站做后台。
      </p>

      <p>前后三个月吧，简历这关算是完毕。</p>

      <p>
        以现在的水平看，其实当年做的真的挺基础的，没有用任何的框架，任何与时俱进的技术。但对于在校学生，没有工业界的打磨，已经是我当年能做到的极限了。至少，比很多千篇一律的学校水项目要强，都是自己认认真真做的，也在其中，真真正正的学到了很多东西。
      </p>

      <p>
        然后就是刷题，原来刷的又重现捡起来。这个时间认识了一个我们学校的神，真心厉害啊，leetcode
        5遍+，见题秒，我亲眼见证了他一天刷100题的壮举。。。真的是几乎没有不会的题，没有他，我刷题中期不可能这么痛快。人家后来基本已经不太刷题了，我问题他就当刷题了。这里感慨一下，有个人在身边，不会马上就解决，这个速度和效率是最快的。很多时候，问到他一些题，他会讲一些他的理解，可能一两句话，比自己刷20，30道都有用，理解马上就上了一个层次。
      </p>

      <p>
        其实后来我才发现，所谓的上课，不就是变相找个人带吗？把他的深入理解告诉你，他给你一道道讲题，对比自己走一步卡一步，速度肯定飞起啊。
      </p>

      <p>
        十月份开始，正式天天刷题，每天晚上白天都刷，一遍遍反复刷，到了11月末刷了200道左右，LC当时基本刷完，就是分类+遍数，我深知我不是一个聪明的人，我刷一遍题根本记不住，我只能傻子一样的一遍遍刷，一遍记不住我刷6遍我就能记住了。所以最后问我刷几遍我真的不知道，有的题10遍都有。十一月初找朋友内推了Facebook，找地里内推了Google。那时候还海投了50家公司发现海投真的一点用都没有。Facebook约面试时间直接约到了1月3号，因为12月安排满了，回我时已经11月20几号了。从十一月开始投facebook开始。开始陆陆续续看面经。我没有投太多家公司，因为学校不是很好也没有别的渠道，career
        fair都是local的公司我一个都不想去。
      </p>

      <p>我只想去Facebook和Google。</p>

      <p>那个时候，满脑子都是这两家公司，破釜沉舟，一意孤行。</p>

      <p>
        然后就是疯狂的刷面经。因为Facebook面经很多，题库就那么多题。一开始，我从Facebook最早的2014，2015年面经开始看，但后来发现，每年的面试官不同，每一波面试官不同，所以每年这波人和那波人出题的题型可能完全不一样，比如今年喜欢move
        zeros，所以这题面经里满天飞，但去年这个时候，move
        zeros就非常少。所以我就从最新的开始看，每天跟踪新的面经，然后倒着看，到面试的时候，从2016年八月开始到2017一月，所有的Facebook面经，一个不落，我全部看过。做题每个题每个解法，follow
        up怎么出，这道题有多少种变形，follow
        up可能是什么答案是什么，都必须会都理解。二轮面试的前一天，我真的不知道看什么了，因为真的感觉差不多都会了。最后就把很多个非leetcode题用eclipse都跑了一遍，稍微难一点的开始写test
        case，加各种注释。
      </p>

      <p>
        这阶段是我刷的最多的时候，Leetcode
        刷了近400，加上面经，不重复的题应该在700+以上，很多时候，一个follow
        up就是一道题了，这个大家都懂。这也是我提升最快的一个阶段，从Leetcode
        到面经的转变，这个过程前期是很痛苦的。一开始看面经一个onsite
        6道左右差不多能做出来的就3道，但主要还是有我那个哥们，不会的他都能搞定。因为Leetcode很多是面经题，但面经题，还是和Leetcode有很大区别的，有些实现题，是很多不会在Leetcode中出现的。这个时候从Leetcode转面经，就是看之前的基础打的牢不牢固了，如果打的牢，可以很快度过，适应面经，如果打的不牢，那就是看啥新题都不会，没有自己能想的出来的。如果遇见这样的情况，我建议大家还是回头去刷Leetcode，补一下做题的基础才是最重要。
      </p>

      <p>
        当年我找实习，前期就拿到了 Facebook 和 Google
        的面试，这也是我最在乎的两家。从来美第一天开始，我就告诉自己一定要去这两家，想抛却以前的一切重新开始。从16年七月份到17年二月份，除了周五周六图书馆12点关，几乎就就没有凌晨4点前回过家。虽然很多人感觉我过的很苦，但至少这两年的留学时光，是我人生最好的两年。
      </p>

      <p>
        学校不行，刚来时有人说没听过谁去过Facebook和Google，当时我就跟同学说如果以前没有，那我就要做这第一个人。
      </p>

      <p>
        后来知道是有很多的，就是大伙不知道而已。一个人孤军奋战，学校里同届刷题几乎没有，天天都是一个人，每天晚上固定时间问我同学今天的问题，累了就出外面散步绕着走一圈回来接着奋战。一路走来，很难，但从来没有怀疑过自己。我原本是个很social的人，但自从来了美国，除了刚开始时多认识人，后来就减少一切社交活动，朋友很少。
      </p>

      <p><strong>因为我知道，我想要的，他们给不了。</strong></p>

      <h2 ref="nav1" id="toc_2"><span>第二年，全职</span></h2>
      <br />

      <p>
        第一年就差不多这么过去了。如果你能读到这里，很多人可能都希望听到一个圆满的结果，最后我
        Facebook 两轮全部 bug free
        一气呵成写出来，其实到最后，面试官出题，写两个单词我都能知道他想出的哪个题，因为做过的题太多，没有没见过的，太熟了。
      </p>

      <p>但等待我的，是一封封拒信。</p>

      <p>
        Facebook Google
        最后因为什么拒的，我到今天也不知道。全挂了之后真的非常颓废，当时写的FB，GG的面经，总有人在下面回，或者微信跟我说，看我的面经拿到offer了，最后我问了5，6个人，谁看完过我写的面经，没有一个人看的完的。后来我数了一下FB写的面经，LC都算，文件有235个，每个文件里面follow
        up多的3，4个，题目数量至少500道以上吧，Google 也有113个文件。
      </p>

      <p>
        后来又陆陆续续投了上百家，但拿到一个面试，太难了。北美求职的都应该知道，能不能拿到面试，是一个非常大的难关。
      </p>

      <p>
        当时我和我那个朋友相依为命，每天我俩累了就出去绕着学校走一圈。那个时候其实我不太知道其他人的水平，我基本不和学校里的任何人联系，微信人也不多。只有几个人一起面一家加了一下，有时候聊一下，所以我也不知道自己的水平处在什么阶段。我现在还记得有一次我俩走到学校停车楼那，算了一下刷了多少题，他跟我说差不多2300左右，就是一遍遍加起来，LC
        5+加各种面经题。我那个时候算了一下，因为我第一学期很多题刷了很多遍，但LC并没有严格的每一道都做算一遍那种，但至少也在能凑到3遍左右吧，加上面经，才1500道左右。当时感觉特别惭愧，因为我总感觉我朋友是北美刷题的平均水平，那些名校毕业的，都能达到这个水平。
      </p>

      <p>
        其实我总以为我学校里的人水平差，当时有一个拿到Amazon实习的哥们听我和我朋友讨论题，说已经根本听不懂你们在说什么了。后来我找全职才知道，原来大家刷的并没有我想象中的那么多，至少刷到我和我朋友的水平，到现在我现实生活也没见过。
      </p>

      <p>
        后来在学期末的时候，那时候稍大一点的公司，都已经结束招实习了。我身边的朋友劝我投一些local小公司，当时真的非常难受，投了几家，都是题都不考的，后来就不投了。其实我感觉，那时候我如果肯投小公司，肯定可以拿到一份offer，我身边一个同学，没有iOS的经验，去了一家小公司做iOS，但三个月的钱没有正常大公司一个月的多。当时我简历还有一半是iOS的项目，让我投我没有投。因为我真的不甘心啊，我付出了那么多，如果去了那些小公司，
      </p>

      <p>都对不起我刷的这些题。</p>

      <p>
        然后暑假又在学校呆着，和我刷题的朋友两个人接着天天成双入对，他当时因为车total
        loss了，我就天天接送他上下学，两人一直在图书馆蹲着。那个时候，还是想更新一下简历，走的web方向，用Python
        的 Django 框架写过网站，Java 的SSM写过，Spring
        Boot写过，把之前的简历又换血了一遍。没事天天再不断的刷刷题。
      </p>

      <p>
        暑期的时候，我兄弟终于算拨云见日，拿到了微软的offer。有一轮面试当场写KMP，20分钟
        bug free
        一气呵成，直接把面试官镇住了，面试官说我就知道有一个解法更好，但从来没有人在我面前写过，我没啥可问你的了，咱俩就聊聊天过吧。
      </p>

      <p>他回来和我说：“KMP这个算法，我写了不下20遍”。</p>

      <p>
        他九月份走了，入职微软。记得有一次我刚回图书馆看他背影，发现，距我第一次见他，整个人都瘦了一圈，衣服都撑不起来了，明显感觉size都小了一号，看的我都心酸。
      </p>

      <p>
        我发自内心的感谢我兄弟。可能每个人会感觉我很幸运，遇见这样一个人，其实当年刷题，一开始是我和他还有一个哥们一起，但他非常不喜欢给那个人讲题。我每次都是研究一个题，查资料，写case，自己试，发现自己哪个地方不明白了，为什么这个结果是这样了，才问我朋友。我知道有的人就直接拿题过来你给我讲讲，但真的感觉，这不是学习的态度。
      </p>

      <p>
        我这个兄弟水平是真的强。但我们学校太差了，一身本事连个面试都拿不到。我俩在一起十一个月，一共就拿到七家面试。我之前总感觉，题到位了，肯定能找到工作，挂了7，8家，就是水平不行。但他就是个活生生的例子，每次，除了运气不好我也不知道怎么形容这种情况了。有公司都店面挺好没下文的；有onsite挺好，现周五打电话夸了一顿，下周二直接拒了说我们想招一个更有经验的；有onsite四轮题全是positive，挂在了最后一轮manager面，内推人都说可惜，manager有一票否决权，后来发现可能更想招一个印度人。。。林林总总，总是有各种意想之外的情况出现。算上我自己，不何尝不是。面试时面试官题写两个单词我就知道啥题了，没有一道题之前没做过的，但就是可以有各种原因挂掉。
      </p>

      <p>他走了，我由衷为他高兴。</p>

      <p>就像在一个战壕里拼杀过的兄弟，我们都是彼此的精神支柱。</p>

      <p>但他走了，就又剩我一个人在这个城市了。</p>

      <p>
        我又回归到了没有遇见他的时候，一个人刷题，吃饭，看电影，投简历。很多时候，一天都不会说一句话，也不记得自己一个人吃过多少次火锅了，每天还是三四点回家，大街上一个人没有，开车回家，单曲循环《世界が终わるまでは》。
      </p>

      <p>那段时光，如果说感受，除了孤独，就是孤独。</p>

      <p>
        每天就是看面经，做题，观察公司，投简历。那个时候，我就明显感受到，自己做题好像已经大成了。每天吃饭刷手机看几道题，基本读完题就感觉题代码怎么写已经在脑子里过了一遍了，一天这么看面经能看个20道左右，有个别有难度的写一下练习一下。我跟我朋友他打电话还说，感觉基本没什么不会了，他还笑说我走了你也出师了。
      </p>

      <p>
        九十月份拿到三家公司面试，indeed，houzz，wepay。这三家面经，按时间从第一个面经开始做，indeed用了一天时间，wepay一天，houzz两天多一点，就把所有面经全都看完做完写完答案。后来感觉做题就是在打字而已。
      </p>

      <p>
        当然，为了面试，每天晚上我还在学校一楼白板上写白板。从最开始写的歪歪斜斜，到最后两米多白板可以写成一条直线。中间还认识个印度哥们天天俩人一人一半白板一起写，我还问过他那边找的怎么样，他说也不行啊，我们也难拿面试啊，他们那边有两个印度妹子倒是最后去了微软，剩下大伙都不好，我说我们这差不多差不多。后半夜天天晚上12点左右图书馆就没人了，练到三四点回家。练口语，各种自己给自己讲。这么写，这么练，得练了近100道吧。效果非常显著，感觉自己那段时间表达能力各种都提升了好几个档次。
      </p>

      <p>
        而且在七月开始，我就开始录题，其实在最开始刷题就感觉，对初学者来说，题目有视频讲解能快非常多。于是在YouTube上录Leetcode题，一天当时最多能录15道吧，平均没事录的也能7，8道。刚开始也没几个人看，更新纯属兴趣。那时候收了一个，嗯，相当于半个徒弟吧，一个大好多的小姐姐，我帮她刷题，其实就是不断录题让她跟着做，每天我录好她看就好。效果还是非常显著的，她自己说以前一天3，4道就到头了，有了我录的题，一天10道她说还能弹弹琴做做饭，不感觉累。断断续续的更吧，前期就她一个人看，她今天看没看我看后台的观看数就行，算半个关门弟子吧。后来她找到工作了，好像有男朋友了，嗯，就不联系了。
      </p>

      <p>
        但17年秋招，就好像有一个魔咒一样，三家面试，又都挂了，其中wepay和indeed，全程聊的特别开心，题秒解，没有任何尬聊。当时这两个接到拒信时，真的每次都有种深深的无力感，已经不是失望了，而是感觉自己穷尽自己所有，感觉自己一切的努力都没有用。我给我哥们打电话，说感觉自己真的没有什么可以提高了，到底是因为什么挂的，我有什么不好你告诉我改啊，现在连自己想提高的地方都不知道。他就安慰我看看他不也是，之前那么多面试，又有多少次都是这样的情况，就是看天意啊。然后houzz的面试，面试题之前也写过，但当时面经没有一个写明白题意的，本身题就是valid
        number题hard变形，然后写代码时间就15分钟。那印度人前期聊天太久了，自己介绍自己连家里几个孩子都跟我说，现在想来好像被曲线黑了，无用话太多了。
      </p>

      <p>那个时候，我大概已经刷了2500道左右了，不重复的各种题，至少1000+了。</p>

      <p>
        无论是做题，口语表达，交流程度，BQ，OOD，Java
        基础等等，只要是北美面试考的，我认为我都已经达到极限了。
      </p>

      <p>
        内推了上百家公司，但一个面试都没有。我给我本科同学打电话说，就感觉自己空有屠龙之术，但没有一条龙可以让我屠的，至少给我一个表现的机会啊，连一个表现的机会都没有。Linkedin加了上千人，加到要买会员才能让我加，30刀不行了60才可以，后来60都不行号都被封了一段时间。为了掌握公司第一手招人信息成立了一个公司情况分享表，号召所有人一起写，最后17年基本所有公司信息都有。
      </p>

      <p>为了找工作，我真的是倾尽了我的所有。</p>

      <p>
        但那时候我才第一次建立群去主动和人接触，才发现所有人并不是我想象中的那么强。2000道以上的人，那500人群我都没有见到过。大多数人还是挺菜的，但很多人就是有offer。
      </p>

      <p>
        最后真的发现，找工作这个事情，运气真的70%，实力30%，谁经历过谁理解这句话的含义。为什么那么多刷题不行的能拿到offer？有些是名校毕业拿到10个面试机会，总会有运气好的题简单就进去的。时运不齐的像我和我朋友这样的，不知道怎么的就是面一个挂一个。
      </p>

      <p>每个人都有自己的求职经历。</p>

      <p>而我们所能做的，或者说唯一能做的，就是把握好这30%的努力。</p>

      <p>
        我后来经常问我的学生，如果你拼了命的努力了，做到了自己的极致，但最终失败，你后悔吗？
      </p>

      <p>我说，</p>

      <p>我无怨无悔。</p>

      <h2 ref="nav2" id="toc_3"><span>第三年，创业</span></h2>
      <br />

      <p>十一月，我突然发现一件事情，我在YouTube上的视频，看的人越来越多。</p>

      <p>
        当时录了200多道了，太多人每天给我留言。我隐隐约约觉得，这个东西，能不能做成一定的规模。原以为北美刷题每个人都应该挺厉害，结果发现90%以上的人都是差的特别多。而且我发现，原来我朋友教给我的方法，我那么多自己研究出来的做题套路，对于题型的整理和分类，遇见什么题怎么最正确的思考，那些很多题目的模版。。等等这些东西真的只有我一个人知道。我总是感觉是因为自己身边的菜，才显的自己刷的还可以，结果却不是的。而且刷题这个事情，16年算是一个分水岭，15年初
        Leetcode 才有100出头的题目，那个时候一个Quick
        Select写出来就是大牛了。但到了年末，就已经350+了。而且这个难度是越来越难，即使是现在，难度也在缓慢增加。我来湾区后和一个Google的员工聊天，他是16年秋招进的Google，自己说现在出题让他做，肯定进不了Google，这是本科浙大研究生也是名校毕业的兄弟亲口和我说的。
      </p>

      <p>15年还有帖子LC刷了7遍的，现在千题时代没有人能做到了。</p>

      <p>
        十月份末时，我YouTube订阅已经超800人了，有的人甚至直接找到我跟我说，愿意2000刀给我，让我给他补补课，说我讲的比他上过的培训班都好。我从一开始只是录着看看能不能帮到别人，从为一个人录，到给很多人录。YouTube后台让我看到仅仅是这些题，流量就已经快有中型网站的流量了，我发现，这个可以做。
      </p>

      <p>
        并且，公司出题的难度，水涨船高，CS行业已经不是几年前百花齐放的时代，从狂热到趋于正常。很多人说刷题不是唯一，对，刷题不是万能的，但不刷题，是万万不能的。在这个行业，没有一个人能一辈子在一个公司，大家都想升职，都想跳槽，只要是写代码的工作，senior
        也要考刷题，都要考算法。今天不做，早晚都会还这个债，总有人说，谁不担心自己突然有一天被PIP呢？
      </p>

      <p>
        而且随着看我视频的人越来越多，找我的人也越来越多。有的北美的培训机构来找我问我能不能去他们那讲课；也有人在我的微信公众号问我想不想加入Leetcode，我猜测是LC员工；也有国内的公司找我让我出算法册子，等等。
      </p>

      <p>
        最重要的是，我录的东西，是真正帮助了很多人。很多人都和我说通过我的视频，拿到了Google，Facebook这种大厂的offer，隔几天就有一个。而且很多人都说是看我的视频活着，说我的视频就像电视剧一样，天天看，有视频才能刷题。我发现我在做的事，是能够真正帮助到别人，是有意义的，是真正能解决大家问题的。
      </p>

      <p>我突然感觉，这是不是一个机会呢？</p>

      <p>手里的最后一个OA，HR给我发邮件催我，我放弃了。</p>

      <p>我想创业。</p>

      <p>
        我把这个想法跟我母亲说，我给我父母看了YouTube后台数据，我说我感觉真的可以，如果不做，妈，我会后悔一辈子。
      </p>

      <p>
        我母亲非常支持我，因为她知道她儿子在美国这，过的并不是像外人想的那样，多姿多彩的留学生活。我的整个留学生活，都是在图书馆做题做项目过来的，每天晚上凌晨3，4回家给父母打电话，从出图书馆开车到家，要近半个小时时间，我每天晚上跟他们说我今天干了什么，做了多少。所以我父母知道我为此付出了多少，既然我感觉可以，那么最坏的结果就是不行找个公司去上班，投的钱我自己也能还。
      </p>

      <p>我想去搏一下。</p>

      <p>
        我非常感谢我的父母，愿意支持我。于是那段时间，我抓紧录题，我把Leetcode前400题所有题都录出来，那段时间很多人说我录题的速度比他们刷的都快。其实是真的非常累，我想把这件事情做到最好，每道题，要看所有的解法，找到最好的解法，去自己些case，一步步跑，然后经常录一遍不成功，一录录3-5遍，有的难题，一录，录几个小时。最后在18年一月中旬把前400题所有全部录完。这其中总有人跟我说，因为看我的视频拿到offer了，说自己天天靠这个视频活着的，说自己做题速度提升2-3倍的，想给钱让我单独辅导的。这里唯有一个大哥，猜出了我想把这个做成事业，非常支持我，给我打了500刀，说就当看了我这么多视频的学费。这是我第一次收到钱，我到现在都非常感谢这个大哥，他给了我很多很多建议，我说大哥最后我开课，你想上哪个上哪个，免费，我真的特别感谢你。
      </p>

      <p>很多时候，不是钱的多少，而是这些，给了我继续下去的信心。</p>

      <p>
        那段时间我拼命做，因为我知道，一旦手里钱花完，我不能再向父母要钱，我要自己养活自己，我需要在美国自己生存下去。我去查三级火箭，要先免费积累用户，有知名度，再收费，我手里的钱能再支撑我多久，这些都是我要考虑的事情。一月录完，二月我开始着手做网站，每天4，5点回家了。我有一个国内网站技术很强的哥们帮我，有不会的，问他都能给我debug，我自己也是边学边做。三月回家一个月，这是我两年来第一次回家。回来后又开始抓紧时间写，那个时候已经没钱了，我也不敢和我妈说，我向我同学借了1500刀，去撑过这段时间，我知道，我必须要盈利，否则，我没法在美国活下去。
      </p>

      <p>
        四月到五月是我这些年最累的一段时光，为了赶进度，主要是钱不够。。借的钱够到五月，但五月下半旬都不够的，必须要在之前盈利让自己活下去。我拼命做网站，那时候有半个月经常是早上7，8点才回家，国内的哥们帮我大忙了，自己的技术水平还是有很大差距的，所以我非常感谢他！最后几天马上就可以上线宣布时，我已经累的开始每天心脏疼了，到最后几天，已经不是隐隐作痛了，强撑着做完。那时候我就感觉到，我已经不能这么熬下去了，再熬下去真的有种随时可能猝死的感觉，我就那段时间天天吃心脏药，我告诉自己上线了肯定不能这样了，毕竟命比什么都重要，不能出师未捷身先死。
      </p>

      <p>五月十二日，网站上线，我正式创业了。</p>

      <p>到此为止，留学两年多，刷题过三千。</p>

      <p>不重复的题，做了1500道以上，剩下的，LC前400，刷了4，5遍左右。</p>

      <p>一天，我 Leetcode 能做100题以上。</p>

      <p>
        这两年多的留学生活，写到这里，感觉就是刷题的两年。刷题这件事，做到了自己的极致。很多人羡慕我为什么刷题那么厉害怎么样的，但你看不到的，
      </p>

      <p>是我凌晨3，4点回家了两年多。</p>

      <p>
        刚开始收费，有一些一直在看的人买，让我缓了一大口气，把同学的钱还了，还有成本，视频云服务器成本还得一点点还给我父母，这个钱我不能让父母拿。还要自己养活自己，每天就是算现在的钱够生活到今年几月份。所以我决定开直播课，先挣钱让自己能在美国生存下去再说，否则一切都是空谈。而且我也在逐渐摸索，到底哪条路才是正确的，才是能让人接受的。我也会思考如何定价，虽然到现在也有很多人感觉价格小贵，但买东西看的是性价比而不是单纯的价格，看投入和未来产出的回报比，比如，北美实习大厂8000一个月，现在把我现有所有的课都买了，都够买好几轮了。国内的培训1.5w，2w起步，我现在课程的价钱，已经是北美的工作，国内的价格了，毕竟国内的单科考研课，单价有的都2w起步了。
      </p>

      <p>
        而且我的课是需要实名制才能看的，因为无论是北美还是国内，有太多不道德的人录视频卖钱了，根本没有一点版权意识。对我来说我的视频是我的全部，我没有工作，它是我唯一的生活来源。因为这个我损失了太多的用户，但我没办法，我必须防止录视频，实名制有人录被发现我就能找到人，没人敢这么干了。没有人和钱过不去，但如果有一个人录了传播，就不会有人买课程。而且国内的很多机构都开始收证件才能提供服务，诈骗什么的只有一个证件现在什么都做不了，为了防这个我还把网站数据库实名图片都移到阿里云上，用户数据除了邮箱全部加密，一年几万刀都是成本。
      </p>

      <p>网站安全有阿里做保障，支付宝能安全，网站也能安全。</p>

      <p>
        然后直播课六月开，我也是第一次讲课，就怕收不来学生，建了一个群一开始连10个人都没有，我就拉了我两个朋友到群里，凑个人数好看点，最后一共收了15个学生，第一期班就算开起来了。
      </p>

      <p>
        那个时候从德州三天开到了加州，睡不着凌晨三点就起来开车。然后在湾区这住了一个车库改的房子，屋子里只有一个封死的窗户能透光，厅里只有一个窗户，进去一股味，有一次我回来一进去就出来了，整个屋子捂的味道特别大，我开门放了二十分钟才进去。晚上睡觉经常热的睡不着，11点躺下2点才睡，想自己赚钱了肯定要住一个正常的房子。但湾区的房价真贵啊，真没钱啊，车库便宜啊，没办法啊。那两个月我一般在图书馆呆着，只有回家睡觉或者讲课才回去。
      </p>

      <p>我也算名副其实的在车库创业了。</p>

      <p>
        其实我特别感谢我的第一批学生，能够相信一个素不相识的人，把两个月时间交给我，里面不乏有哈佛，伯克利，加州理工这些名校的学生，有的是国内武大浙大还没过来的学生。有四月末刚刚决定转专业，以前学文科的小姐姐，四月开始接触cs，以前做public
        healthy的，六月上我的课，很多基本的数据结构都没听说过，最后两个月也能300道。还有以前上过那种原价6500刀培训班感觉学不到的，在我这又学了一遍，中间这哥们把工作都辞了全职跟我刷，最后也能300道以上。我就想，这么多人相信我支持我，
      </p>

      <p>我不能让他们失望。</p>

      <p>
        那时就体会到，当这种老师，责任真的太大了。因为学生找你的时候，基本都是走投无路的时候了，很多人是真的弄不明白了，过几个月就秋招开始了，最后一个暑假，能不能找到工作，是把所有的希望都寄托在你的身上。那个时候我就感觉到，钱真的是其次的，就是这份责任，我必须要扛起。
      </p>

      <p>
        同样，前期创业，真的是不断摸索，在这个直播课阶段，我真的感觉，CS这个行业，做直播讲技术，真的不行。注意，是不行。因为我讲课发现，CS的逻辑性太强了，每次课，每个人的智商不同理解能力不同，导致每个人听懂多少都不一样，很简单的例子，就是高中数学，老师上课讲完，还有一些不懂的问同学，CS很多比高中数学难多了，不可能当堂理解。我讲课时发现，没有任何一个人可以在我讲课时完全懂我说的东西，每个人都回头去看回放的视频，每个人都会看两遍，反馈就是这个东西必须看两遍，因为如果没有做题，讲的东西很多没有切身体会根本不能理解的那么透彻，做完题再看视频，感觉是另一番天地一样。而且我讲课时从来不讲题，课上现场写代码，试了一次太费时间，直播这么做就是在磨时间。所以我全部都是提前写完给学生看，要不根本讲不完，这8周最后算了一下有5周都加过课的。课上完全就是讲知识讲方法就讲了两个月，这要是在别的地方就得讲6个月了，因为一旦现场写题，时间会几何倍上涨。
      </p>

      <p>
        而且我自己的精力也不够，我有太多的事情要做，这两个月最后我也是强撑下来。最主要的是，这个直播的模式，不行。这也是为什么北美这么多培训机构，每次开班都有学生录视频，然后私下传播的，因为不是学生想录，
      </p>

      <p>而是学生一遍理解不了啊！</p>

      <p>
        可能很多人说直播有互动，但我上课发现，就是我这种15人超小班，这个互动也是聊胜于无，因为学生在什么都不懂的阶段，你说什么都是新的，大部分都是在传授，你讲什么他接受，他来不及有自己的思考，而只有思考才能后才能提问题。我能保证我的课每个人当堂听懂，但思考是课下才能有疑问，都是课下自己回看视频回味了才有问题。有的上来100多人的大课，又怎么互动，互动又有几人呢？所以我上完这个直播课，我就不打算再开直播了。
      </p>

      <p>这个算法直播课是我第一次直播课，也是我最后一次直播课。</p>

      <p>
        八月份，我搬了家，来美两年多，终于住了一个正常的房子。回想我从来美国到现在，第一个房子是睡大厅，第二个是个破小区家里有老鼠也一股味道，曾经两点回家被老鼠凌晨四点赶到图书馆地上睡过，第三个也不是一个严格意义的屋子，是二楼的一个小厅，门是玻璃对开门锁不了的，只能靠一个帘当着，第四个车库。最终我搬进了如今的家，才感觉原来住个正常的屋子是这么好。但还是会发愁，创业这样的模式并不稳定，一个月有多少收入纯看天意，我只能做好自己一点点把脑子里的东西录出来。还是会想房租在哪里，同学想让我十月出去玩，还是会想这个钱从哪里出，能不能挣到。看着本科同学固定工作一个月到手七，八千刀，有的有房有车有妹子，我这租房快10年破车没妹子，
      </p>

      <p>我是真羡慕啊！</p>

      <p>
        接下来的生活，我就是打磨自己的课程，录课，录题。因为在那个时候，我就没事调查很多在线教育和培训这两个行业，我的想法很简单：如果我要做，就要把这个东西做到最好，要不就别做。所以我自己给自己定了一个目标，就是要好好打磨自己的课程，把直播的体系，重新录，转换为录播课程。因为我一开始就没想着挣钱去做这件事，真的是走投无路了，面试都拿不到，才创业。所以看问题的角度，感觉有时候还是很单纯吧。我认为，无论做培训还是在线教育，都需要把自己的内容放在第一位，这是持续下去，做好的本质。
      </p>

      <p>
        所以我用了整整一年的时间，从17年8月，到19年7月，整整12个的时间，录课，把整个体系，全部一点一滴录出来。
      </p>

      <p>
        我想把这件事做好，不想让任何事打扰我，我创业第一年，除了Youtube和知乎几个回答，谁看到谁感兴趣就来买，我不想让金钱打扰我自己的心境，我安安静静就在家自己录课，从来没做过宣传。
      </p>

      <p>我告诫自己，</p>

      <p>脚踏实地，不忘初衷。</p>

      <h2 ref="nav3" id="toc_4"><span>第四年 2019</span></h2>
      <br />

      <p>
        毕竟是踏入教育行业，我总是在思考，如何当一个合格的老师，这个行业的现状怎么样，优缺点是什么。但说到“老师”这个词，我其实从来不让我的学生叫我老师的，因为从内心深处来说，我认为我不配这个称号。
      </p>

      <p>
        “师者，传道授业解惑也”，缺一不可。而我能做到的，仅仅是“授业解惑”，而最重要的“传道”，我是没有这个能力去做的。我的学生，本身很多都是这个世界最优秀的人才了，来自世界顶级名校。我92年出生，很多在职跳槽的都比我大，我只能说术业有专攻。而我的学生，只有把我整个体系全学到手，我才感觉是我教的。所以我手把手带出来的学生，我都当朋友对待。很多小朋友都仅仅比我小几岁，很多都直接叫我哥，有些比我大的学生，我都直接叫人家大哥，小姐姐的。
      </p>

      <p>
        但我总是想在这两个月的时间里，教给他们一些能够终身受用的东西。很多还在上学的小朋友，给我的感觉，就两个字：浮躁。很多人不能踏踏实实的，自己心里特别清楚自己现在是在多紧要关头，但不能脚踏实地的干。很多都没踏入社会，没有遇见什么挫折磨难，很多人是没有什么事情让他们竭尽全力去做的。男生，很多感觉自己脑子活，感觉刷题自己能行，偷懒没认真，一考就露馅。女生，很多感觉自己没天赋，学不明白，妄自菲薄。
      </p>

      <p>
        所以我遇到这些人，男生，我总是告诉他们，脚踏实地，无论干什么事情，都需要踏踏实实的，刷题，那么就一道一道题的认真做，我想让他们在这两个月，就认真做这一件事。因为我认为，一法通时万法通，做好一件事，这件事给你带来的感悟，会影响到做其他事。踏实认真刷题，以后遇见问题，也会自己明白，只有付出真正的努力，才能回报。这个世界上是没有任何事情有捷径的，唯一的捷径就是踏实的努力。永远不要想着自己聪明就可以别人刷10道我刷8道，总会害了自己。这世界上也没有什么东西是可以速成的，即使我所谓的“速成班”，也仅仅是省去了自己理解，找资料，困惑的时间，题，一道都不能少。我也会和学生讲自己上学时总是说一周搞定一本书什么的，到头来发现，自己所有取得的成就的事情，都是自己真正努力过才成的，而那些小聪明做的事，没有一个成的。
      </p>

      <p>不积跬步，无以至千里；不积小流，无以成江海。</p>

      <p>
        女孩子们，其实给我的感觉，很多都没什么自信。感觉刷题难，自己不适合cs，学不明白。其实哪有什么学不明白，就是没掌握方法而已。做一道题，首先题意都需要理解很长时间，然后直接硬想，10分钟都想不出来，挫败感已经出现。看答案，答案又五花八门，不知道哪个对，然后有的答案，看了半个小时都看不明白，一写总有bug，又不知道哪来的bug。于是妹子积极性被严重打击，误以为自己不适合CS。其实就是方法不对，而且基础薄弱，不知道特定的方法和技巧，需要引导。
      </p>

      <p>
        而且大多数学生，无论男女，很多是一路顺风顺水，没有经历过什么磨难，很多毅力并不是那么强。我全力以赴的学生，每个都和我说过，做梦都梦见刷题，一天下来，可能到晚上6，7点，就脑子里完全进不去任何东西了，看视频也只能感觉是听声音而已，这一天才罢休。我知道，每个努力的人，应该都经历过这个状态。我和学生说，做梦没梦见刷题，就是没做到极限。这话不是说说玩玩，日有所思夜有所梦，你真的认真了，你肯定做梦会梦见的。所以我总是告诉他们，认真，全力，好好做，你不比任何人差，
      </p>

      <p>只是，你没那么努力罢了。</p>

      <p>
        我希望上这个课的人，可以全心全意做这一件事，以后，也可以认真对待任何事。让他们在两个月内知道，自己做一件事，是可以做到好的，那么以后任何事，都有信心，不输任何人，因为知道自己可以做好任何事。
      </p>

      <p>
        我每个速成班的学生，我都想尽量做到，因材施教，我知道我能做到的有限，但真的想，做好老师这个工作，教他们一些学习的方法，不是只是告诉答案。有问题，我告诉他们怎么去debug，怎么去打印输出，查资料，自己去尝试自己解决问题，最后不行了，再问别人。即使是问问题，也一定要经过自己思考。因为CS这个行业，是一定要有自己解决问题的能力的，自己去发现问题在哪里，而不是遇见问题，像初高中一样，问老师，以后工作，都是解决不了再问别人，工作中，问别人问题，是人情啊。
      </p>

      <p>
        并且我也研究怎么让学生能有真正的提高，我们的目的是面试，我的课就是纯功利性课程，不是随便一个网课，你上与不上就是个充电，很多是抱着把刷题这件事解决的想法来上课的，那么课程的效果必须要有。而我把直播变成录播后，最大的问题，就是录播完成率太低了，业界显示录播完成率4%上下而已。所以我把课程设置有效期，如果看了一段时间不能看了，至少很多人会因为不能白花钱把课程看完，能提高录播的完成率。而我给的时间，是正常看一遍的几倍时间。刷题本身就不是一个长期活，如果抱着刷一年的想法，那么我建议先别刷了，说明还根本没有做好准备，这东西就是短期4-6个月内搞定的。并且我的课都是不给代码的，如果仅仅是看的爽，不落实到代码上，一点用没有。我基础知识，题型总结两门课，加起来70多个小时，不给代码，就是想让上课的人，自己记笔记，自己写。
      </p>

      <p>不写代码，永远还是我的知识，而不是你的。</p>

      <p>
        而且我自己设计的课程体系，我认为是最好的刷题体系，不会有之一了。我是把基础知识，和题型总结分开，到Leetcode一道道题讲解。对于基础，我在一开始做题时，其实感觉也不重要，感觉就那点东西，我还是科班出身。但仔细想想，学校讲的那些基础知识，真的能对应到刷题吗？基本就是告诉你有这个数据结构有这个东西而已，就不能再多了，到题的灵活应用，作用聊胜于无。基础知识，我也是在我刷题大成了，再去重新翻了若干遍《算法导论》，再结合对应题，我才发现有这么多东西。《算法导论》这本书我翻了几遍基本都翻烂了，但说实话，如果没有大量题的基础，是看不出什么东西的。但现在市面上，是没有任何一门课，是可以把基础知识，和做题进行完美结合的。我知道，每个人都着急，所以我讲课时，把基础和题型分开，让即使是初学者，也可以看完一部分基础，去做对应的题型，第一天就可以做题。基础知识，我把基础和Leetcode结合，数据结构的实现，Java
        源码的分析，这其实就已经比学校里讲的深入太多了，学校只会讲这个数据结构的用处，只有个别几个如heap等会实现，其他都是停留在“知道，了解”层面，对做题没有用处。而我的基础和做题一起，是“运用”的阶段，
      </p>

      <p>你都已经会熟练运用了，还不了解知道吗？</p>

      <p>
        基础结束后，后面《题型技巧总结》，各种奇技淫巧，各种我自己琢磨的独家方法套路，直接让
        hard 变成
        medium。到《Leetcode题目视频讲解》，这个才是做题利器，每一行代码的意思，我都会去讲，会去跑case，每道题都分析到每个变量，细致到每一行的coding，所以初学者一天10题真的很容易。
      </p>

      <p>
        我的课，就是从小到大，每一个小的细节，到大的环节，每个算法，都全面细致。
      </p>

      <p>
        最后整个体系录下来，自己的水平又提升了一大截。我真的感觉，这世上如果说做公司面试题，做题比我厉害的肯定有，虽然我现在还没见过。但说总结方法，对题的理解，没人能超过我了。大家都知道给人讲明白和自己做是两码事。所以如果以后有人能超过我，那么前提是像我一样，3000+题量，Leetcode自己全讲一遍，算法导论通读，自己总结基础题型，并且全部都讲出来，录出来。
      </p>

      <p>
        所以到现在为止，只要上过我课的学生，没有任何一个人质疑过我的水平。Leetcode题都录明白很难，但最让人服的，是《题型技巧总结》的所有方法技巧。刷了400+的人看完，所有人都会一拍大腿原来是这样。毕竟有的学生400+题量来上课，如果没有真本事，是真的不会服你的。
      </p>

      <p>
        其实对于刷题这件事，很多人总是讨论怎么最快的刷题。如果是自己刷题，我认为只能以题带练，直接看答案，去做。这个是我写的答案，如何最快速的刷题。但其实我自己录完这些视频，回想自己做题时光，还是感觉看视频有人给你讲最快，这是毋庸置疑的。因为当年自己一天三题，一道题2-3个小时太正常了，都是这么难过来的，有的难题，看不懂真的就不看懂了，没人问。真的有个视频能讲明白，方法总结到位，不用自己去浪费时间找答案理解，真的快太多了。而且我的方法是3000题的心得，自己做没有量的积累是总结不出来的，因为对题的理解程度也不一样。
      </p>

      <p>2019年暑期，我又收过两次手把手带的学生。</p>

      <p>
        其实学生一多，就什么情况都有了。因为我是秉着“我一定对你负责”的态度做这个事情。所以我是基本两周至少给每一个人打个电话，做的好的继续保持我不打扰，平时做的不好的，咱们就需要额外再打打电话聊聊，有的就一周一个了，没事我也会微信上催一下。“这周为啥没完成啊”“现在刷题感觉怎么样啊”“感觉现在状态如何啊”等等。有的嫌我烦的，就不接我电话了，发消息就没有回复了。。
      </p>

      <p>
        我是不想像市面上大多数培训机构一样，“会叫的孩子有奶喝”，我是会主动找学生去聊，去主动催他们，你完不成咱俩就单挑一下到底怎么回事为什么没完成。很多学生内向，他们有问题其实不会主动找你，你找他们的时候，他们才会想起来才会问。所以一打电话，有的学生打一个多小时很正常，到最后就不是只刷题，就聊一些自己的学习态度啊什么的。我始终感觉内因才是最重要的，需要让学生自己去意识到这个刷题到重要性，他们自然会做好，只靠我去催外力是不行的，我也不能打个飞机找到人打两巴掌这样。所以经常话题就是“从小到大学习毛病”“我做事静不下心”这种。
      </p>

      <p>
        最后相处下来，我们是朋友，是为了一个目标而努力的战壕里的战友，不是师生。
      </p>

      <p>
        另外我这人就实话实说，你做题不行我就直接说不行，每个人我都一个个面试过，做题水平我一清二楚，我就敢说“你是咱们这个班的倒数第一”“你现在这个状态基本面什么挂什么”。很多学生好像还都挺喜欢听。。。因为我的观点就是，你来我这不是过家家玩来了，我们马上要面试了要找工作了啊！我们是要出成绩的，你最后没有达到面试的要求就是我的失败。所以我不想说那些套话，我也不想贩卖焦虑，就事实说话，因为过一个月就面试了你自己说现在这水平能过哪家。有的甚至和我说老大你多说我两句让我认清现实，没关系。好吧我也是醉了。。。
      </p>

      <p>
        这样做，其实最后的成果也非常显著。基本每期学生，两个月刷完300以上的，50%以上，暑期有一个班刷完前400题就50%了，300以上80%。有零基础文科转专业，35岁+小姐姐的，最后两个月300+；有转专业在校学生小姑娘，本科机械，两个月400+；有400+题量还来上课的，代码水平真的很强，但依然和我说这个课没白上，很多技巧上课才知道；也有大哥40了做到manager了换工作刷题，工作经常7，8点下班和我说晚上无论几点都要做两小时题再睡觉，最后跳槽50w+大包，如果在Google对应到的Lv6～Lv7之间的职位了，两个月300+题量；还有好几个6500刀的课上过，有的上了这么贵的课就刷了120题的，来我这两个月回炉重造刷了300多道；花钱最多的，有一个学生报过北美VVIP的那种万刀的课，一年过去了也才刷了100多，和我说我报了这么贵的课还是要看你的视频，犹豫要不要再报我的，因为已经花了太多的钱了，我和她说，我的班绝对就是你最后一个花钱的班，两个月，400+。
      </p>

      <p>
        有人问我为什么速成班的评论有人写那么多，我说我和学生的关系好，我也没想到写那么多。其实关键原因，是我的课，真的解决了他们的问题，真的达到了报课的目的，真正的，把刷题这件事，解决了。
      </p>

      <p>
        其实这么多学生，最厉害的是一个30+的小姐姐，有次直播课没来和我请假，过两周打电话时和我说当时请假去医院生下娃。。。我当时是真的一脸懵逼，小姐姐背景是学纽约电影的，老公在匹兹堡做码农所以就结婚后转行念书，暑假还在上了网课还在我这刷题还怀孕生娃。。两个月407道题，之前一道题没做过。
      </p>

      <p>
        所以我总是对比，为什么人家能做到，到你这做不到，论忙谁都比不上天天上班manager的大哥，人家家里还有五岁女儿呢。而论精力状态是个人都不能比孕妇还低吧。不就是和人家的努力，学习态度各方面有差距吗，没有任何其他借口啊。我对很多学生都说过，不要总焦虑自己怕找不到工作，先想想自己努力没有。如果你面试挂了，是因为题，是自己知道哪里有没准备到的，那就别怨天尤人，就是努力没够。如果你努力够了结果还是没找到，你认为自己无论是面试表现还是做题都没有问题还是挂了，那你还会后悔吗？
      </p>

      <p>没有一个人和我说后悔的。</p>

      <p>但也没有一个人做到真的认为自己完美无缺的。</p>

      <p>
        其实最终结果就是，面试并没有那么难。一个姐姐说过，她也是我速成班的学生，是个两个孩子的母亲，大的都九岁了。她就和我说，我之前面小公司电面都没有过的，上你这个班，现在所有的电面基本都能过，我已经达到我上这个课的标准了，但还没有达到你的标准，你的标准太高了。我说我就是以Google为目标要求所有人的，我从来没有让我的学生目标是个小公司，我们的目标就是FMAG，就是世界最顶级的公司。
      </p>

      <p>因为他们真的不难，真的离我们近在咫尺。</p>

      <p>
        去年出师的学生，我朋友帮我内推Facebook，前几个月吃饭聊天和我说，我去年推了118个人，最后拿到全职offer的一共就两个人，一个是个PHD，另一个就是你的学生。
      </p>

      <p>当时内推八个人，只有那一个学生拿到面试机会。</p>

      <p>你敢给我面试机会，我就能面进去。</p>

      <p>
        我那个学生最后拿到了Microsoft和Facebook的offer，Google面到一半不面了，拿了两个感觉够了。小姑娘的实力当年我就很认可，我知道她肯定能成功。其实只要两个月能坚持下来的，结果都不会差，因为首先题这关过得去，那么面试最难的环节就过去了。这两个月，不仅仅只是刷题，是对一个人毅力，学习态度方方面面的考核，所以坚持下去的，结果没有一个差的。找实习的和我说，全系100多人就10个左右找到的，有他一个。找全职去Microsoft，Amazon的都有。我才教了几个人啊，第一批学生8个达到我的预期，全职FMAG基本凑全，剩下几个实习没有没找到的。我后来回访时有的人和我说他在学校就是被别人仰望的大神。
      </p>

      <p>
        所以我对我的学生，我认可的学生都非常有自信。我和我朋友说，我让你推的人，都是我亲自手把手带出来的，都是我单挑面试过的，水平绝对没问题。我自己看我要内推的学生，我对每个人都有信心。
      </p>

      <p>
        但我对学生说，你最后能两个月刷这么多题，以后拿到offer，都是你自己努力得来的，我只能起20-30%的作用，关键还是在于你自己。老师这个工作，真的只能引导和教方法，如果一个人怎么催都不做，或者理都不理我，神仙都没有办法。所有课前咨询问有多少人拿到offer的，我只能说他是他，你是你。问最后效果能怎么样的，我只能回答：
      </p>

      <p>取决于你自己的努力。</p>

      <p>天道酬勤。</p>

      <p>写到这里，已经接近结尾了。</p>

      <p>
        其实一路走来，我终于找到了自己喜欢做的事情，我每天醒来，都感觉自己在做自己喜欢的，不是去当大公司的螺丝钉。我从开始刷题就想如果有人能够把题录出来讲解，那么肯定可以减轻太多负担，一开始录题，也从来没有想过自己可以单独干，只是凭着兴趣，后来帮助一个人去录，再后来，发现那么多人看我的视频，最后，找工作不顺利，才发现我可以另辟蹊径做起来。以前我也不相信那些企业家，总感觉初衷就是为了盈利。到我自己才发现，可能我们的初衷，就是想把这个做好，想去帮助更多的人，但无意中发现，我们可以做更大做更多的事情，可以通过自己的努力，去改变这个世界。现在的我，还没有这个力量，但是，至少我可以改变这个北美留学圈，去让更多的人受益，去减轻更多人的负担。
      </p>

      <p>我在做有意义的事情。</p>

      <p>所以今年即使Google，Amazon很多HR主动找我面试，我也都拒绝了。</p>

      <p>
        我知道前路依然会坎坷，会困难重重，创业本就是极大的风险，我每天还是会焦虑，会感觉朝不保夕，还是会累的像狗一样，但我从来没感觉到苦。回想留学两年多，外人看来每天都过的苦兮兮的，自己回想好像也的确有点，但我每天都是精力充沛，至少，我在做我喜欢做的事情。我不是什么天才，我感觉以我的智商考清北再让我复读一年我也考不上，我能做的只有努力。以前本科时，我可以算是学院出了名的浪，各种社团结识各种人，什么都经历过，从来没当过学霸。两年的留学时光，感觉自己真的是从当年的浮躁，一点点沉淀下来，终于意识到，最快的捷径，就是没有捷径。到现在为止，我所有得到的东西，真的都是自己一点点努力得来的，没有一个是天上掉馅饼砸中的。
      </p>

      <p>留学两年，是我这辈子到现在最有意义的两年，是成长最快的两年。</p>

      <p>
        留学的两年，终于想清楚，自己想要什么，自己想要成为什么样的人。留学生活并不是改变你让你成为一个什么样的人，而是放大了自己，无限放大了原本的自己。这两年自己除了学习，就是不断的思考，经常绕着学校一圈圈的走，去想一些自己原本没时间想的事情。想自己看过的书，经历的事，想起当年老师的教诲，不断反思自己之前的所作所为，有没有不当的地方，有什么做的不好的可以改正的，有些事自己自私了没有，如果再见到当年那些我对不起的人我应该道歉。还有哪些弯路和错误是不能再犯的，我自己究竟想要一个什么样的生活，自己这辈子最想要的是什么。这些问题，都在自己独处时一点点想清，我从来就不是什么圣人，我需要不断的完善自己。
      </p>

      <p>
        后来来了湾区，我也尽量把生活过的简单，因为我不想让任何事情打扰我现在的状态。我把创业这件事放在我人生的第一位，我是在做事业，不是在过家家玩。我竭尽自己的全力，把这件事做好。我出门在外，从来不说自己创业，陌生人问干什么的都是说startup后端程序员，不想让那些虚名影响到我。以前本科时总是羡慕学习好的大神，但当自己被别人这么说时，真的不是很喜欢这个称号，因为我知道自己真的没有什么了不起，我唯一比他人强的地方，就是刷题的数量，比大多数人多罢了。
      </p>

      <p>所以我告诉我自己的学生，你和我刷一样多的题，也能像我一样。</p>

      <p>
        但是，我也告诉他们，不要走像我一样的路，你们应该有属于自己的生活，每个人的追求，理想都是不一样的，去走一条属于自己的路。我的路，只能是作为前人给你的一个参考和建议，能让你少走一些弯路，因为，
      </p>

      <p>每个人都有属于自己的留学生活。</p>

      <h2 ref="nav4" id="toc_5">
        <span>北美留学何去何从，留学生涯感悟</span>
      </h2>
      <br />

      <p>
        在这里我想谈谈我自己来这两年的感想，感觉如果是在国内，这些事情是肯定不会想的，但来了美国，很多之前从来没有细想过的问题，都开始不断的思考。
      </p>

      <p>
        首先是计算机这个行业。北美这讨论最多的，就是CS这个行业，真的可以长久不衰吗？来美国之前，就有一句话，来美国只有两个专业：一个是CS，一个是非CS。我本科就是软件工程，这么多年，接触越多，越深切的感觉到：以现在的发展来看，至少未来百年以内，还是CS的天下。拿一个最简单论据来说，我们现在所憧憬的未来所有的设备，未来生活，都是计算机的分支领域。而现在各种风投最看重的，资本最在乎的，还是属于计算机的领域发展。北美这因为CS的公司众多，其他工作机会的减少，H1B很多公司都越来越不支持，万众转CS。而且北美这，CS的工资基本在同行业属于一骑绝尘，刚毕业如果股票都算上能大厂15w+真的太多了，会计等行业工作几年都赶不上new
        grad的工资，别的材料，EE等，工作也是非常难找。很多人为了CS工资都开始转CS，很多人感觉这是一种畸形，但如果CS的发展不好，又怎么可能会有这种情况出现？
      </p>

      <p>
        如今的北美CS，发展已经不是五年前大厂不要太容易的感觉。从互联网时代现在已经趋紧于平稳，几年前还是有很多人凭一个点子几个人弄个网站就能占据一角。但现在已经越来越趋向于技术方面的壁垒以达到发展的目的。但目前的很多领域还是属于大伙往里砸钱赌未来赌发展，AI的公司没有几家能盈利的，融资如果一旦断了都死，都是靠不断的融资画饼，先把技术方面做好产品有了，但一旦发展好了赌对了，未尝不能成为一方霸主。同样，留给年轻人的选择说多也多，说少也少。多是大厂的细分领域，各个领域方面的蓬勃发展，年轻人的选择越来越多；少，对于很多想干一番事业的人，大厂以逐渐形成一种垄断的形式。现在就是我看你感觉有前景我还没涉及到这部分，那么我拿钱砸过去收购你。而且像之前所说的，技术现在基本已经形成第一要素。年轻人没有工资经验，何谈技术？而且现在很多东西以数据为第一，任何大数据其实都是活生生的钱，大厂可以利用数据去做很多事情，尤其是AI领域，没有数据基本很难有一个结果，数据第一。任何行业领域，前期可以拼产品，拼质量，到最后都是以形成技术的垄断作为最终杀手锏。所以，难。但现有时代，能在一个小的领域做到第一，都可以轻松实现财富自由。但说的轻松，做起来难上加难。
      </p>

      <p>但我还是特别相信一句话：我们缺的不是机会，而是缺发现机会的眼光。</p>

      <p>
        而且CS涉足的人越来越多，因为范围太广了，AI，无人车，VR等等这些街上大妈都知道的东西，很多穷我们一生感觉也是刚刚踏出一步。而且CS还是英雄不问出处的最好代表，对于我们国家来说，高科技领域有太多的地方落后，缺芯少核的问题已经大白于天下，这些太多的技术已经落后人几十年的差距。唯有计算机领域算是赶上这波浪潮，能否弯道超车就是在此一役。现有的开源资源，很多东西基本都是无时间地域限制接轨。是机会，也是机遇。
      </p>

      <p>
        所以说，这个时代，是从蒸汽时代的第一次工业革命，到内燃机的第二次，而我们赶上的第三次信息革命，有太多的空白，秩序没有形成，所以现在各种公司以群雄逐鹿的形式占据各种细分领域，什么时候计算机行业衰落？那就要看看这波信息红利什么时候结束，第四次革命到底是什么了，从现在来看，没人能知道。
      </p>

      <p>
        所以我认为，所有学CS的人，都站在一个时代的浪尖上，而这个时代的名字，就叫做：
      </p>

      <p>白(ji)胡(suan)子(ji)！</p>

      <p>第二点就是，徘徊在中美的留学生，何去何从？</p>

      <p>
        对CS来说，我始终不赞成毕业就回国这个打算，我支持回国，但不是毕业回去。我本科同学经过两年的工作经验，很多人都已经达到了年薪30w的水平，我只知道我仅仅毕业回国工作，公司是给不到这个数字的，而且很多留学的人都或多或少考虑过一个问题：我什么时候能把留学的钱赚回来？从经济对比来说，毕业回国不划算。
      </p>

      <p>
        但如果有了工作经验，Google，facebook
        这种大公司光环在身，身价肯定不一样。如今国内也不是几年前高薪聘请国外大厂出来的，但至少可以和当年的同学差不多甚至高一点。我身边接触的人来说，现在的留学生很大一部分已经有回国的倾向，暂且不论文化等因素，国内的发展，市场，机遇，各种综合因素蒸蒸日上。而美国，举一个我个人的感触，我两年前来美国，三月回国时，感觉美国这两年没有任何变化。但国内，我看见了滴滴的兴起，单车，移动支付。要知道一个政令下达到全部执行还需2-4年的时间，而仅仅两年的时间，我感觉中国有了巨大的改变，这种普及到民生的改变，每一个改变都是商机，都是机遇。中国的一个市场，就等同于北美+欧洲，这个潜力是巨大的。北上广的房价固然可怕，但湾区我一个Facebook的朋友说，去年旁边一个房子还90w呢，今年已经120w了，我的工资才涨了1w。当然这可能是比较好的地段，但也侧面反应了，美国也不是大家想象的那么好，在大城市，房价没有低的，无论在哪里，压力都是存在的。
      </p>

      <p>
        其实回国这个选择，呆在美国时间越长，越感觉每个人都不一样。见过太多的人，很多人感觉国内不好，没什么发展；很多人感觉国内才是王道，现在已经从回国就是loser逐渐变成回国的才是大神。我只能说每个人的观点和选择不同。我肯定是要回国的，因为我接受不了自己的孩子变成一个中文都不会说的ABC，土生土长美国长大的，很少有中文说利索的，大多比较好的都是小时候在国内，10岁左右才来的，但写依然不行。在美国越久，越感觉自己是一个彻彻底底的中国人，吃也吃不到一起去，其实这一点我就足以回国了。。而且考虑到未来的发展，和一个在美国呆了二十年的程序员大叔聊过，大叔没有明说，但言语中很是羡慕我们这些年轻人，感觉自己在美国生活的已经没有了激情。的确，很多时候在美国的日子可以一眼望到头，但国内则充满了无限的可能，当然，也更难。
      </p>

      <p>
        另外，一个中国人的公司是不会有升职的天花板存在的，但美国则不同。参加过一个金融的饭局一个大叔说到，以前工作的一个地方出了级别特别高的中国人，具体记不清了，整个地区的人都知道了，因为这么多年，从来没有过。还有父母的考虑，这一代的我们基本都是独生子女，古人云，父母在，不远游。即使时代不一样，但依然有一定的道理。来美国第二年，我爷爷突发心梗去世，当时正赶上期末，根本没法回去，我只能在回国的时候在爷爷的坟前多磕了三个头。子欲养而亲不待，我想象不到如果自己的父母那时候不在身边，我肯定不会原谅自己，会后悔一辈子。而且美国在我的感觉来说正在走下坡路，只是底子太厚，再支撑几十年都没有问题。经常看到一些人讨论美国科技人员的断层，各种工科的课基本被印度人中国人占领，网上经常有人说有的教授讲了一会突然摔笔说，我真的不知道我现在是为了哪个国家培养人才。美国人挣钱的行业集中在医学，法律，金融，很多优秀的人都去了这些学院，而未来真正比拼的理工科，却是真正的要后继无人。举一个最实在的例子，各种大厂要是把亚洲人都裁了，还有几个能活下去？
      </p>

      <p>
        最后还有一些听起来很中二的想法，为国效力。有时候感觉，自己在美国做的再好，创造的价值都是美国的，虽然都是为了这个世界做贡献，但套用钱老的一句话，程序是有没有国界的，程序员是有国界的。小初高时最好的朋友写自己的座右铭，为中华的崛起而读书。无论最后实现与否，我们都曾有一个为国争光效力的心。
      </p>

      <p>
        况且，每一个程序员都会或多或少有改变世界的心吧。没有天花板的存在，我们始终有机会去做更大的事业。“为天地立心，为生民立命，为往圣继绝学，为万世开太平”，不知道自己终我一生能做到哪一点，但至少，我想试试，我想凭自己的努力，能做到什么程度。我二十五年的求学之路，有很多个老师，给我影响最大的，是我初中的老师，很多时候我感觉我不是由父母教育出来的，而是那位我三年的班主任教育的。他给我影响最大的是三句话，我此生铭记。
      </p>

      <p>
        第一句，如果你努力，至少还有1%的希望，如果不努力，连1%的希望都没有。所以我不知道自己的极限在哪里，但我至少可以努力去尝试，即使失败了，但求问心无愧，无怨无悔。就像亮剑里说的，即使是面对天下第一剑客，也要亮出自己的宝剑，即使是倒在对手的剑下，那也叫虽败犹荣。
      </p>

      <p>
        第二句，只要你想做，全世界都会为你让路。所以我相信人定胜天，冥冥中自有天意，但最终的一切，必不可少的就是自己的努力。我深切的感觉到，我拼命的想刷好题，我就能遇见我最后去微软的兄弟，我想写网站，我就能遇见帮我的国内的哥们。想做这件事，就会吸引身边的人，所有的困难都终会解决，会有人帮你。
      </p>

      <p>最后，天下兴亡，谁的责任！我的责任！</p>

      <p>
        如果说整个留学生涯我最大的成就，其实不是我刷了3000多的题，而是我同学说的一句话。那时候我们刚入学认识，他说咱们学校，能去Amazon就是最好的结果了，Google，Facebook这种太难了，不太现实。当我面完实习GG，FB时，最后都挂了，他对我说，兄弟，之前我感觉GG，FB这种离我们太遥远，因为你，我感觉它们并不是遥不可及，也许我也可以试试。当时我就想起了那句话：
      </p>

      <p>
        “当我年轻的时候，我梦想改变这个世界；当我成熟以后，我发现我不能够改变这个世界，我将目光缩短了些，决定只改变我的国家；当我进入暮年以后，我发现我不能够改变我们的国家，我的最后愿望仅仅是改变一下我的家庭，但是，这也不可能。当我现在躺在床上，行将就木时，我突然意识到：如果一开始我仅仅去改变我自己，然后，我可能改变我的家庭；在家人的帮助和鼓励下，我可能为国家做一些事情；然后，谁知道呢?我甚至可能改变这个世界。”
      </p>

      <p>
        所以那一刻我发现，我可以努力，去改变自己，改变身边人，改变我的国家，这个世界。我有多大的能力，做多大的事情。中国还有那么多我们可以去做去改变的，我想哪怕有这个国家因为我，有一点小小的进步，我都足以感到自豪。那么我现在至少可以改变北美的CS求职，我可以放手一搏。况且中国有那么多的问题，很多人一谈到国内的各种食品问题，环境问题，就喜欢对比美国多么多么好，说回国没意思，想让自己的孩子过更好的生活。但我想的是，
      </p>

      <p>难道不应该是知道这些问题，看看我们自己能为此努力改变多少吗？</p>

      <p>我的责任啊。</p>

      <p>
        我知道一个人的力量有限，中国的各种问题需要每个行业，不同领域的人一起去努力。那至少，在计算机领域，我们可以做一些我们力所能及的事情。而不是看到我们自己国家有问题去逃避，去贬低。这个时代在改变，在前进，中国能否在这个时代实现弯道超车，关键在我们这个时代的中国人能否团结一心。少年强，则国强。更不用说中国已经在逐渐兴起，比起之前的一穷二白好太多了。
      </p>

      <p>
        现在计算机领域各个领域，都是有大前景和发展的。光无人应用这块，就是一个足以支撑亿万产业的领域，可以让一个国家实现飞一般的超越。而同样中国的老龄化趋势严重，十年前我们可以用廉价劳动力去市场竞争，但劳动力的成本上升是毋庸置疑的事实。人口红利的效果已经越来越小，未来比拼的我们人口红利下的人才红利，中国有全世界人数最多的工程师，当下我们最需要的，是在人数最多中出现最顶尖的工程师。同样，未来的趋势是用技术取代一切人为可能产生的成本，而我国劳动力的下降必须要靠我们这代人的技术革新去支撑。
      </p>

      <p>而现在的技术革新，没有哪个是可以避开计算机的。</p>

      <p>
        所以我们才会要大力发展AI，而国家当务之急，就是把各个行业，产业链的每一个环节，都抓到自己的手里，才能不受制于人。我们要做到这一点，我们努力，也能做到这一点。
      </p>

      <p>
        我们还是有很长的路要走。我们的制造业还需要再接再厉，我们需要进行产业升级，重视技术的研发，引进先进的管理模式，去淘汰老旧不思进取的企业，进行新旧交替；我们也需要注重知识产权，让付出的人没有白白付出，完善我们的金融，法律体系；加大投入在缺失领域，不能让中兴的悲剧发生第二遍，希望国家真的能拿出几百亿去解决缺芯少核的问题。别人经济制裁时，不让大豆影响我们的民生。作为计算机领域的从业者，我们的发展的确好，但还是需要培养各种领域的大牛，我们需要踏实下来有认真去做科研的人，去有我们自己的内核操作系统。。我们已经有了很多领域的确取得了世界著名的成绩，高铁已经做到第一，但我们还是在很多核心部件需要他国的支持，否则难以为继。无论我们现在取得了多么瞩目的成就，但还是，有那么多行业，领域，让人感觉赶超是希望渺茫的，这是需要我们每一个中国人在各行各业努力的，需要我们这一代人去挺身而出的。
      </p>

      <p>
        还有各种食品问题，环境问题，老龄化问题。。尤其是老龄化问题，我们这一代大多都是独生子女，两个年轻人要抚养四个老人。这里不得不提一下我国的医疗，还好我们的医疗做的还可以，如果要是像美国的医疗体制，年轻人更无以为继。四个老人的压力真的非常大，我认为2020-2040年这二十年将是我国老龄化矛盾最突出的时候，需要我们去提高我们的生产力，以技术去革新行业，才能创造更高的价值维系我们的老龄化社会。
      </p>

      <p>
        任何的破而后立，都是机会和机遇的体现。所以我还是支持我们留学生回国，无论是我们身上的责任，还是中国的未来发展，都值得我们回去。
      </p>

      <p>
        写了这么多，最后的最后，我想给看这个文章的所有留学生们，莘莘学子们，一条建议，只有一条。因为这是我前25年学生生涯结束后，我认为最重要的东西：
      </p>

      <p>
        你一定要知道，你内心最深处，最想要的是什么，你想要成为一个什么样的人。
      </p>

      <p>
        自从创业以后，我接触了越来越多的人。原来羡慕名校毕业的，感觉肯定个个是天之骄子，心有乾坤内有宇宙，但我所接触的人，大多数并不是这样。我看到的，是一双双迷茫的双眼，学习成绩对大多数人来说是一种对待事情认真的本能，但一到工作岗位上，能做好自己分内的工作，然后自己的未来在哪里，没有人能说出个所以然。我认为这不应该属于我们当代大学生的状态，哪怕你只是想要一个平淡的生活，也应该对自己的生活有一个憧憬，也应该知道自己内心最深处最想要什么，也应该知道自己到底想要成为一个什么样的人。
      </p>

      <p>
        我从小到大，唯独小学是上的当地最好的小学，初中不是当地最好的，现在来看属于排不上号的那种，高中也不是那种特别好的高中，大学也算不上国内一流，研究生更不用说了。但无论怎样，即使做轮椅上了半年学，拄拐上了两年学，休学了三年，我也没有降级。我不是什么天才，我很多时候起步都是20多名开始，最后以7，8名收场。但至少，中国科举制的环境下，每一轮的筛选和淘汰，都挺了过来。摸爬滚打磕磕绊绊，总是踩着边缘赶上了大众的脚步。
      </p>

      <p>因为在我内心深处，我不愿落人后，我不想被任何人淘汰掉。</p>

      <p>
        到了研究生阶段，两年的独处时光，我终于想清了自己，我自己这辈子，我想要一个精彩的人生，我想每一件事情，都付出自己的全力，我对我的生活充满激情，这样我才能领略淋漓尽致的快乐与痛苦；我愿去努力实现我的理想，即使失败了也在所不惜。如果有一天自己的孩子想要的生活不是我所承认的，我想我不会去阻拦，不会拿自己的标准去强加于人，我会对他说：
      </p>

      <p>Just do it，like your father</p>

      <p>
        不要把自己未完成的心愿寄托下一代，比如我因为没有上过名校，总是羡慕名校毕业的人，但我不会希望我孩子一定要去。自己没做到的，自己去实现，把希望寄托到孩子身上算什么本事。
      </p>

      <p>
        而出国之后，我才意识到，每个人都是独立的个体，不是每个人都像我想的那样，每个人必有雄心壮志干一番事业的想法，每个人都心有鸿鹄之志，每个人都可以舍我其谁。这些，只是当年我个人对于人优秀的定义而已。而每个人都有自己的想法，不是每个人都想要轰轰烈烈的人生，每个人的选择都不竞相同，这并没有好与坏之分。
      </p>

      <p>只要我们做到遵从我们的内心的渴望，不让自己后悔，就已经足够了。</p>

      <p>
        我明白这个道理以后，才意识到，我的想法仅仅是我的想法而已，我有自己的思考我努力就可以。我从来不是什么天才，但我相信“古之成大事者，不惟有超世才，必有不坠青云之志”。那么我有自己的理想和志向，那仰望天空时我必须脚踏实地去做。
      </p>

      <p>
        所以当你知道内心渴望什么时，将不会有迷茫，都会有自己的目标，即使前路困难重重，我们也不至于迷失方向。但认清自己并不是一件容易的事情，这世上的大多数人一辈子都没有想明白这个问题，这和每个人接触的环境不同，思想成熟度不同，你在进步，你的想法也会随之改变，你每时每刻都有现阶段的理想和目标。
      </p>

      <p>但你灵魂深处最本质的渴望，却永远不会随着时间改变。</p>

      <p>
        所以留学生活，不是让你变成了什么样的人，而是在远离约束高度自由的环境，你是什么样的人，进行了无限倍的放大。
      </p>

      <p>
        在留学生涯中，我遇见的学生大多数都被找工作弄的焦头烂额，但有时候也会问一些人，你想要的什么是什么。很多都说找到工作再想吧，也有很多说上班可以安安静静平平淡淡过完一生，也有很多女孩子说找个喜欢的人生个孩子小日子不错。上班后的人问这个问题，很多就已经不一样了，见过有想法做东西想做一番事业的，见过安稳挺好的。所以人与人之所以不同产生最后的不同路径和差距，是因为这些不能用数据衡量的内心深处，决定着每个人最终的人生轨迹。而到了有一天真正选择时，选出来的，将会是你内心的真实反馈。
      </p>

      <p>而在学生阶段，只是在统一标准下掩饰了人与人的不同罢了。</p>

      <p>人生的路很长，而因为内心的渴望，决定了最终道路的不同。</p>

      <p>
        所以如果你现阶段没有找到自己的目标，不知道怎么真正想要什么，多去尝试，年轻时最不怕的就是犯错，努力去寻找。自己努力提高，努力去学习，让自己变的更好更优秀，女孩子家也要努力，安全感是自己给自己的。而且无论男女，充实自己让自己更自信，不要说谁谁谁是大神，只要你想，你努力也是可以的。
      </p>

      <p>我们都只是普通人而已。</p>

      <p>
        最后，我希望我的这些想法可以给大家一些启发和思考。留学在外，我们很多人获得的资源已经远超大多数人，我始终认为我们是有能力做好一些事情。但我也并不想给大家压力，感觉读了就必须要回国之类的。因为国家的繁荣昌盛的目的，不就是为了国民更好的生活吗？我只希望同学们读完之后，不要在这个年纪迷茫，可以思考一下自己以后的路，思考一下自己的内心真正渴望的是什么，
      </p>

      <p>遵从你自己内心的决定，</p>

      <p>然后，</p>

      <p>去走一条属于自己的路。</p>

      <p align="right"><strong>Best regards</strong></p>
      <p align="right"><strong>Edward Shi</strong></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "Story",
  data() {
    return {
      left_fixed: false,
      nav: [
        {
          is: true,
          name: "第一年 实习",
        },
        {
          is: false,
          name: "第二年 全职",
        },
        {
          is: false,
          name: "第三年 创业",
        },
        {
          is: false,
          name: "第四年 2019",
        },
        {
          is: false,
          name: "留学生涯感悟",
        },
      ],
    };
  },
  methods: {
    scrollTop() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.left_fixed = scrollTop > 475;
    },
    navTo(querry, i) {
      this.$el.querySelector(querry).scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      });
      console.log("1111" + querry);
    },
    handleScroll() {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // this.fix = scrollTop > 290 ? true : false;
      if (scrollTop < 475) {
        this.nav[0].is = true;
      } else {
        this.nav.map((it, i) => {
          return (it.is = false);
        });
        for (let i = this.nav.length - 1; i >= 0; i--) {
          if (scrollTop >= this.$refs["nav" + i].offsetTop) {
            this.nav[i].is = true;
            break;
          }
        }
      }
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollTop);
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    //离开该页面需要移除这个监听的事件
    document.removeEventListener("scroll", this.scrollTop);
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.teacherArea {
  margin-top: -36px;
  text-align: center;
  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    vertical-align: top;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  }
  .teacherName {
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.800000011920929);
    line-height: 18px;
    margin-top: 16px;
  }
  .teacherDes1 {
    font-size: 12px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: rgba(26, 26, 26, 0.4000000059604645);
    line-height: 12px;
    margin-top: 8px;
  }
  .teacherDes2 {
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.4000000059604645);
    line-height: 16px;
    margin-top: 10px;
  }
}
.mainInfo {
  margin-top: 36px;
  margin-bottom: 10px;
  padding: 0 10px;
  p {
    font-size: 12px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: rgba(26, 26, 26, 0.800000011920929);
    line-height: 15px;
  }
}
.legal {
  background-color: #f7f8f8;
}
.legal h2 {
  position: relative;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
.legal h2 span {
  position: relative;
  z-index: 9;
  display: inline-block;
}
.legal h2::before {
  display: block;
  position: absolute;
  content: "CSPIRATION";
  height: 72px;
  font-size: 50px;
  font-weight: 800;
  color: rgba(240, 241, 242, 0.8);
  height: 72px;
  top: 0;
  left: 0;
  text-align: center;
}
.legal blockquote {
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
.legal p {
  line-height: 20px;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin-bottom: 26px;
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}
.legal p strong {
  font-size: 18px;
}
.jumpNav {
  padding: 6px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .st-link {
    font-size: 14px;
    width: 24%;
    text-align: center;
    padding: 8px 12px;
    background: rgba(52, 73, 94, 0.10000000149011612);
    border-radius: 1px 1px 1px 1px;
    margin: 6px 0;
    &.active {
      background: #fa6400;
      border-radius: 1px 1px 1px 1px;
      color: #fff;
    }
  }
  #totop {
    color: #fa6400;
    text-decoration: underline;
    background: #fff;
  }
}
.is_fixed {
  position: fixed;
  left: 0;
  width: calc(100% - 20px);
  top: 54px;
  z-index: 1000;
}
</style>